// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import { useSelector } from 'store';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { drawerOpen, openedForm } = useSelector((state) => state.menu);
    const { sectionName: title } = useSelector((state) => state.section);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 260,
                    height: 88,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    backgroundColor: drawerOpen ? theme.palette.secondary.light : 'transparent',
                    paddingX: '2rem',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    <LogoSection />
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1, marginLeft: 'calc(225px + 2rem)' }}>
                {(!openedForm || (openedForm && drawerOpen)) && (
                    <Typography variant="h3" color="secondary" fontWeight={500} fontFamily="Poppins, sans-serif">
                        {title}
                    </Typography>
                )}
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
